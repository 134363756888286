<script setup>
import { useScroll } from '@vueuse/core';
import { v4 as uuidv4 } from "uuid";

const slidesHandling = useSlideHandling();
const {onMouseUp} = useMouseEvents();
const {onDebouncedWindowEvent} = useWindowEvents()

const slideControlDragging = useState('slideControlDragging', () => false)

const props = defineProps({
  size: {
    type: Number,
    default: null
  },
  gap: {
    type: Number,
    default: null
  },
  withNavigation: Boolean,
  useEmbedState: Boolean
})

const emits = defineEmits(['swipeEnd', 'initiated'])

const backendStore = useBackendStore();
const { isEmbed } = backendStore;

const touch = ref(false);
const startTouch = ref(0);
const endTouch = ref(0);
const scrollLeft = ref(0);
const refControl = ref();
const {isScrolling} = useScroll(refControl);

const refSlideNavigation = ref();

const slots = defineSlots();
const itemsCount = ref(0);
const controlWidth = ref(0);
const controlScrollWidth = ref(0);

const slides = computed(() => Math.round(controlScrollWidth.value / (slideSize.value + gapSize.value)));
const slidesVisible = computed(() => Math.floor(controlWidth.value / (slideSize.value + gapSize.value)));

const currentSlide = ref(1);

const setControlSizes = () => {
  if (!refControl.value) return
  controlWidth.value = refControl.value.getBoundingClientRect().width;
  controlScrollWidth.value = refControl.value.scrollWidth;
}

const slideSize = computed(() => {
  if (null === props.size) {
    return slidesHandling.slideSize.value
  }
  return props.size
})

const gapSize = computed(() => {
  if (null === props.gap) {
    return slidesHandling.gapSize;
  }
  return props.gap;
})

const scrollToSlide = (slide) => {
  refControl.value.scrollTo({
    left: (slide - 1) * (slideSize.value + gapSize.value),
    behavior: "smooth"
  })
}

const getCurrentSlide = (fromSlide = null) => {
  let slideNumber;
  let offset = 0;
  let delta = Math.abs(fromSlide || 0);
  const minSize = (slideSize.value + gapSize.value) / 5;
  const halfSize = (slideSize.value + gapSize.value) / 2

  if (fromSlide) {
      if(delta >= minSize && delta < halfSize) {
        offset = fromSlide > 0 ? 1 : -1;
      }
  }
  slideNumber = Math.round(refControl.value.scrollLeft / (slideSize.value + gapSize.value)) + 1 + offset;
  return slideNumber;
}

const progressSlide = (value) => {
  if (value < 0) {
    currentSlide.value = Math.max(1, currentSlide.value + value);
  } else {
    currentSlide.value = Math.min(slides.value - slidesVisible.value + 1, currentSlide.value + value);
  }
  // console.log(currentSlide.value,slides.value, slidesVisible.value);
  return currentSlide.value;
}

const nextSlide = () => {
  scrollToSlide(progressSlide(1));
}

const prevSlide = () => {
  scrollToSlide(progressSlide(-1));
}

const startDrag = (e) => {
  const pageX = e.touches ? e.touches[0].pageX : e.pageX;
  touch.value = true;
  startTouch.value = pageX;
  endTouch.value = pageX;
  scrollLeft.value = refControl.value.scrollLeft;
}
const moveDrag = (e) => {
  if (!touch.value) return true;
  slideControlDragging.value = true;
  const pageX = e.touches ? e.touches[0].pageX : e.pageX;
  endTouch.value = pageX;
  const offset = (startTouch.value - pageX)
  refControl.value.scrollLeft = scrollLeft.value + offset;
}

const endDrag = (e) => {
  const offset = (startTouch.value - endTouch.value)
  const visibleSlide = getCurrentSlide(offset);
  scrollToSlide(getCurrentSlide(offset));
  currentSlide.value = getCurrentSlide(offset);
  touch.value = false;
  slideControlDragging.value = false;
  emits('swipeEnd', visibleSlide);

  if (props.withNavigation) {
    refSlideNavigation.value.setSlide(visibleSlide);
  }
}

const refresh = () => {
  endDrag();
}

const handleClick = (e) => {
  e.stopPropagation();
  if (touch.value) {
    e.preventDefault();
    e.stopPropagation();
  }
}

onMounted(() => {
  setControlSizes();
  let slotContent = slots.default();
  if (slotContent[0] && slotContent[0].__v_skip && slotContent.length === 1) {
    slotContent = slotContent[0].children;
  }

  itemsCount.value = slots.default()[0].children.length - 1;
  currentSlide.value = getCurrentSlide();
  emits('initiated', itemsCount.value)
})

onDebouncedWindowEvent('resize', () => {
  setControlSizes();
})

const uniqueId = "chk_" + uuidv4();
const onScrollStop = () => {
  currentSlide.value = getCurrentSlide();
}

const onScrollStart = () => {

}


let startedScroll = false;
let timeoutEventHandle = null;
watch(() => isScrolling.value,
  (v) => {
    if (!startedScroll && v) {
      startedScroll = true;
      onScrollStart();
    }
    clearTimeout(timeoutEventHandle);
    if (!v) {
      timeoutEventHandle = setTimeout(() => {
        startedScroll = false;
        onScrollStop();
      }, 100);
    }
  }
)


defineExpose({
  scrollToSlide,
  nextSlide,
  prevSlide,
  itemsCount,
  refresh
})

</script>

<template>
  <div ref="refControl"
       class="group/control flex overflow-x-hidden snap-x snap-mandatory pl-4 pt-4 pb-10 -mx-4 md:-ml-4"
       :class="{'gap-6':!gap, 'mr-0': isEmbed && props.useEmbedState, 'pr-6': !isEmbed || !props.useEmbedState}"
       :style="gap ? {'gap': `${gap}px`} : {}"
       @touchstart="startDrag"
       @touchmove="moveDrag"
       @touchend="endDrag"
       @mousedown="startDrag"
       @mousemove="moveDrag"
       @mouseup="endDrag"
       @click="handleClick"

  >
    <slot />
  </div>
</template>
